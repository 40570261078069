.button-basic {
  background: white;
  padding: 10px;
  font-size: 25px;
  border: 2px solid #3787c5;
  border-radius: 51px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  color: #3787c5;
}
.button-basic:hover {
  background: rgb(60, 60, 60);
  padding: 10px;
  border: 2px solid rgb(60, 60, 60);
  border-radius: 51px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 600;
  color: white;
}

.button-section-contact {
  background: #3787c5;
  padding: 10px;
  font-size: 15px;
  border: 2px solid #3787c5;
  border-radius: 51px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  text-align: left;
  color: white;
}

.button-section-contact:hover {
  background: #44fff6;
  padding: 10px;
  font-size: 15px;
  border: 2px solid #44fff6;
  border-radius: 51px;
  padding-left: 20px;
  padding-right: 20px;
  font-weight: 400;
  color: black;
}
.button-section-result {
  background: #3787c5;
  padding: 10px;
  font-size: 15px;
  border: 2px solid #3787c5;
  border-radius: 51px;
  font-weight: 400;
  text-align: left;
  color: white;
  width: 70%;
}
.button-section-result:hover {
  background: #1ff1e7;
  color: black;
  border: 2px solid #1ff1e7;
}
@media (max-width: 765px) {
  .button-basic {
    font-size: 15px;
  }
}

@media (max-width: 324px) {
  .button-basic {
    font-size: 12px;
  }
}
