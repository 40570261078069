.dropdown-toggle::after {
  color: transparent !important;
}
.container-fluid-footer {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}
.shared {
  float: right;
}
.footer,
.inter-footer {
  background: #3787c5;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  letter-spacing: 2px;
  text-align: center;
}
.nav-bar-footer-p {
  color: white;
  font-weight: 400;
  font-size: 16px;
}
.inter-footer-text {
  font-size: 30px;
  line-height: 51px;
  color: white;
  font-style: italic;
  font-weight: 400;
}
.inter-footer-text span {
  color: #3787c5;
}
.inter-footer-text-second {
  font-size: 30px;
  color: #777777;
  font-style: italic;
  text-align: right;
  font-weight: 400;
}
.nav-bar-footer a:hover,
.nav-bar-footer a:active,
.nav-bar-footer a:focus {
  text-decoration: none !important;
}

.nav-bar-footer a :hover,
.nav-bar-footer a :active,
.nav-bar-footer a :focus {
  color: #87deff !important;
}
.copyrigth {
  align-self: center;
}
.copyrigth p {
  font-weight: bold;
  color: white;
}
.bubble-inter-footer {
  width: 231px;
  height: 231px;
  border-radius: 160px;
  position: absolute;
  max-width: max-content;
  margin-top: -67px;
}
.bubble-inter-second-footer {
  width: 160px;
  height: 160px;
  border-radius: 160px;
  position: absolute;
  max-width: max-content;
  margin-top: 72px;
  margin-left: 72px;
}
.bubble-inter-third-footer {
  width: 30px;
  height: 30px;
  border-radius: 200px;
  position: absolute;
  max-width: max-content;
  margin-top: 157px;
  margin-left: 22px;
}
.icon-inter-footer {
  width: 380px;
  max-width: max-content;
}
.image_footer_2 img {
  width: 100%;
}
.footer-text{
  display: flex;
  justify-content: space-between;
}
.content-footer-30{
  width: 30%;
  color: white;
}
.content-footer-30:hover{
   color: white;
   text-decoration: underline;
}
@media (max-width: 900px) {
  .footer-text {
    display: flex;
    flex-flow: column;
    gap: 8px;
}
.content-footer-30{
  width: 100%;
}
  .icon-inter-footer {
    width: 100%;
    max-width: max-content;
  }
}
@media (max-width: 650px) {
  .inter-footer-text {
    font-size: 13px;
    line-height: 28px;
    color: white;
    font-style: italic;
    font-weight: 400;
  }
  .inter-footer-text-second {
    font-size: 20px;
    color: #777777;
    font-style: italic;
    text-align: right;
    font-weight: 400;
  }
}
