.title2 {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}
.title-white {
  font-weight: bold;
  color: white;
  font-size: 59px;
}
.title-pink {
  font-weight: bold;
  color: #3787c5;
  font-size: 59px;
}
.title-semi-pink {
  font-weight: bold;
  color: #3787c5;
  font-size: 59px;
}
.title-section-aviso-privacidad{
    font-size: 32px;
    line-height: normal;
    font-weight: 700;
    color: #3787c5;
    padding-bottom: 6px !important;
    text-align: center;
}
.subtitle {
  color: white;
  line-height: 18px;
}
.title-data {
  font-size: 59px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}
@media (max-width: 900px) {
  .title-section-aviso-privacidad{
    font-size: 22px;
    padding: 0px;
   }
  .title-white,
  .title-pink,
  .title-semi-pink,
  .title-data {
    font-size: 27px;
  }
}
