@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Ubuntu:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&family=Ubuntu:wght@300&display=swap');
* {
  font-family: 'Montserrat', sans-serif;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
.text-align-end {
  text-align: end;
}
a {
  text-decoration: none;
}
.text-align-webkit-center {
  text-align: -webkit-center;
}
.background-fixed {
  /*background-image: url(../img/pages/fondo.jpg);*/
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #282828;
  height: 100%;
}
.w20 {
  width: 20%;
}
.placeholder-center {
  place-content: center;
}
.overflow-hidden {
  overflow: hidden;
}
.fs-18 {
  font-size: 18px;
}
.mb-50 {
  margin-bottom: 50px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.title {
  color: white;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.pink {
  color: #3787c5;
}
.mt-6 {
  margin-top: 6rem !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.mb-6 {
  margin-bottom: 6rem !important;
}
.padding {
  padding-top: 200px;
}
.bg-blue {
  background: #0c1f2c;
}
.p-52 {
  padding: 52px;
}
.pb-30{
  padding-bottom: 30px;
}
.bold {
  font-weight: bold;
}
.line-22 {
  line-height: 22px;
}
.ml-7 {
  margin-left: 7px;
}
.pr-0 {
  padding-right: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.fs-32 {
  font-size: 32px;
}
.pt-58 {
  padding-top: 58px;
}
.img-circle {
  width: 231px;
  height: 231px;
  border-radius: 160px;
  max-width: max-content;
}
.text-basic {
  color: #3787c5;
}
.mt-115 {
  margin-top: 115px;
}
.mt-77 {
  margin-top: 77px;
}
.background-black {
  background: #282828;
}
.mr-0 {
  margin-right: 0px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.pt-90 {
  padding-top: 90px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.text-left {
  text-align: left;
}
.bg-opacity-black {
  background-color: #000000ba;
}
.carousel-control-prev,
.carousel-control-next {
  display: none;
}
.section-tools .carousel {
  height: 240px;
}
.bg-opacity-medium-black {
  background-color: transparent;
}
.bg-opacity-white {
  background-color: #ffffffe6;
}
.pt-43 {
  padding-top: 43px;
}
.pb-43 {
  padding-bottom: 43px;
}
.mt65 {
  margin-top: 65px;
}
.responsive {
  width: 100%;
}
input:hover {
  background-image: linear-gradient(
    to right,
    rgb(144, 200, 238),
    rgb(233, 231, 255)
  );
}

.error {
  color: #ff2d2d;
  font-weight: 600;
}
.mt-8 {
  margin-top: 8rem !important;
}
.text-gray {
  color: rgb(197 197 197);
}
.font-weight-100 {
  font-weight: 100;
}
.fs-20 {
  font-size: 20px;
}
input {
  border-radius: 19px;
}
.pl-2 {
  padding-left: 2rem;
}
.pl-4 {
  padding-left: 4rem;
}
.text-align-center {
  text-align: -webkit-center;
}
.pt-100 {
  padding-top: 100px;
}
.logo {
  width: 258px;
}
.background-transparent {
  background: transparent;
}
.text-bold {
  font-weight: bold;
}
.pt-121 {
  padding-top: 121px;
}
.pt-105 {
  padding-top: 105px;
}

@media (max-width: 1200px) {
  .mm-0 {
    margin: 0px;
  }
}
@media (max-width: 800px) {
  .offcanvas-body {
    background-color: black;
  }
  .nav-bar-p {
    color: black;
  }
  .mm-0 {
    margin: 0px;
  }
  .mrm-0 {
    margin-right: 0px !important;
  }
  .mlm-0 {
    margin-left: 0px !important;
  }
  .mbm-15 {
    margin-bottom: 15px;
  }
  .wm100 {
    width: 100%;
    height: auto;
  }
  .mm-1 {
    margin: 1px;
  }
  .mt65 {
    margin-top: 40px;
    margin-bottom: -26px;
  }
  .img-responsive {
    width: 100%;
  }

  .logo {
    width: 200px;
  }
  .col-mb-100 {
    width: 100% !important;
  }
  .p-52 {
    padding: 22px;
  }
}

@media (min-width: 1200px) {
  .container-fluid {
    max-width: 1055px !important;
  }
  .container-static {
    max-width: 1122px !important;
    margin-right: auto;
    margin-left: auto;
  }
  .navbar-expand-lg .container-fluid {
    max-width: 1302px !important;
  }
}

@media (max-width: 600px) {
  .mmb-2 {
    margin-bottom: 2rem !important;
  }
  .mmt-0 {
    margin-top: 0px !important;
  }
}

@media (max-width: 500px) {
  .fixed-top {
    position: inherit !important;
  }
  .ptm-60 {
    padding-top: 60px;
  }
  .ptm-18 {
    padding-top: 18px;
  }
  .ptm-0 {
    padding-top: 0px !important;
  }
  .mtm-0 {
    margin-top: 0px !important;
  }
  .mtm-25 {
    margin-top: 25px !important;
  }
}
