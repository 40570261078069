.cards {
  background: white;
  border-radius: 10px;
  border: 4px solid #787878;
  box-shadow: 20px 20px 50px 10px #ece9e9 inset;
  padding: 11px;
  margin: 7px;
  height: fit-content;
}
.card-generic {
  margin-bottom: 30px;
  border: 0px !important;
}
.cards-1 {
  margin-bottom: 25px;
}
.card-image-generic {
  width: 100%;
  height: 200px;
}
.card-image-generic-response {
  width: 100%;
  height: auto;
}
.card-title-generic {
  text-rendering: optimizeLegibility;
  color: #6fa8dc;
  margin: 0;
  word-wrap: normal;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 33px;
  margin-top: 19px;
  text-align: center;
  height: 97px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.card-generic-button {
  width: 100%;
  border: 0;
  margin-top: 20px;
  outline: none;
  padding: 13px;
  background: rgba(36, 67, 102, 1);
}
.card-generic-button a {
  color: white;
  font-size: 19px;
  font-weight: 500;
}
.card-generic-text {
  font-size: 18px;
  font-weight: 500;
  color: #666666;
}
.title-card {
  color: #3787c5;
  font-size: 14px;
  text-align: left;
}
.text-card {
  font-size: 14px;
  color: #000;
  text-align: left;
  line-height: normal;
}
.text-card-1 {
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
}
.card-image-generic1 {
  width: 45%;
  height: 174px;
}
.img-card-1 {
  margin-bottom: 20px;
}
.card-title-generic-1 {
  text-rendering: optimizeLegibility;
  color: #0b5394;
  margin: 0;
  word-wrap: normal;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 33px;
  margin-top: 19px;
  height: 97px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.card-text-generic-1 {
  color: #3d85c6;
  word-wrap: normal;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 33px;
}
.card-generic1 {
  border: 0px !important;
  margin-bottom: 60px !important;
}
.card-generic2 {
  margin-bottom: 30px;
  border: 0px !important;
}
.container-card-generic-optimization {
  margin-top: 70px;
}
.card-title-generic2 {
  text-rendering: optimizeLegibility;
  color: #3787c5;
  margin: 0;
  word-wrap: normal;
  font-size: 23px;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 33px;
  margin-top: 19px;
  text-align: center;
  margin-bottom: 19px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
.card-generic-text2 {
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
}
@media (max-width: 600px) {
  .container-card-generic-optimization {
    margin-top: 30px;
  }
  .cards {
    margin: 0px;
    margin-bottom: 10px;
  }
  .card-image-generic1 {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 400px) {
  .card-generic-text2,
  .text-card-1 {
    font-size: 15px;
    font-weight: normal;
    line-height: 25px;
  }
  .card-generic1 {
    border: 0px !important;
    margin-bottom: 12px !important;
  }
  .card-title-generic {
    text-rendering: optimizeLegibility;
    color: #6fa8dc;
    margin: 0;
    word-wrap: normal;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: normal;
    line-height: 31px;
    margin-top: 19px;
    text-align: center;
    height: 64px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
}
