.wrapper {
  /* Keeping the Text in the 
       center of the Screen */
  display: flex;
  justify-content: center;
  margin-top: 75px;
}

.typewriter h1 {
  overflow: hidden;
  white-space: nowrap;
  font-size: 56px;
  font-weight: 600;
  margin: 0 auto;
  color: white;
  animation: typing 3.5s steps(30, end);
}
.container-effect-1 {
  background: linear-gradient(135deg, #3787c5, #bbbbbb);
  height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
/* Typing Effect on Website Loading */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@media (max-width: 1015px) {
  .typewriter h1 {
    font-size: 30px;
  }
}
@media (max-width: 800px) {
  .typewriter {
    padding: 18px;
  }
  .container-effect-1 {
    height: 33vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .typewriter h1 {
    font-size: 25px;
    font-weight: 600;
    white-space: normal;
    margin: 0 auto;
    color: white;
    animation: typing 3.5s steps(30, end);
  }
}
