.background-home {
  height: 800px;
  background: #3787c5;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: -50px;
  justify-content: center;
}
.card-image-6 {
  width: 210px;
  margin-top: 14px;
  margin-bottom: 47px;
}
.card-image-5 {
  width: 267px;
  margin-top: 51px;
  margin-bottom: 52px;
}
.background-content {
  font-family: Arial;
  text-align: center;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.8);
}
.card-image-generic-service {
  width: 200px;
  margin-bottom: 20px;
}
.card-image-generic-service-1 {
  width: 201px;
  margin-bottom: 20px;
}
.section-img-contact {
  align-self: center;
  text-align-last: center;
}
.img-contact {
  width: 405px !important;
}
.img-card-material-2 {
  margin-top: 21px !important;
  margin-bottom: 56px !important;
}
.img-card-material-4 {
  margin-top: 21px;
  margin-bottom: 56px;
}
.img-card-1 {
  width: 181px;
}
.waves {
  position: absolute;
  bottom: 0px;
  height: 527px;
  width: 100%;
  overflow: hidden;
}
.wave {
  position: absolute;
  left: -180px;
  bottom: 0;
  width: 2402px;
  height: 427px;
  background: url(https://static.platzi.com/media/files/waves_c8551f5d-ecf6-4a81-ae1d-f3a0ad55ba10.png)
    center bottom no-repeat;
  animation: 5s wave ease-in-out infinite alternate;
}
.wave.a {
  background-position: 0 -854px;
}
.wave.b {
  background-position: 0 -427px;
  animation-delay: 0.6s;
}
.wave.c {
  background-position: 0 0;
  animation-delay: 1.2s;
}
.title-first {
  color: #ffffff;
  font-weight: bold;
  vertical-align: baseline;
  font-size: 48px;
}
.text-first {
  color: #ffffff;
  vertical-align: baseline;
  font-size: 40px;
}
.second-about {
  font-size: 21px;
  line-height: 28px !important;
}
@keyframes wave {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-80px, 30px);
  }
  100% {
    transform: translate(160px, -60px);
  }
}
@import url('https://fonts.googleapis.com/css?family=Roboto:700');
@keyframes showTopText {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes showBottomText {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.animated-title {
  color: #222;
  height: 100vmin;
  left: 50%;
  font-weight: 600;
  position: absolute;
  top: 44%;
  transform: translate(-50%, -50%);
  width: 90vmin;
  margin-top: 105px;
}
.animated-title > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 100%;
}
.section-client {
  background: white;
}
.title-exploration {
  font-size: 32px !important;
  padding-top: 35px;
}
.title-exploration p {
  font-size: 32px !important;
}
.background-gray {
  background: #414141;
  margin-bottom: -18px;
  margin-top: -49px;
  padding-bottom: 45px;
}
.section-tools {
  background: transparent;
  padding: 20px;
}
.animated-title > div div {
  font-size: 32px;
  padding: 2vmin 0;
  position: absolute;
}
.animated-title > div div span {
  display: block;
}
.animated-title > div.text-top {
  border-bottom: 1vmin solid #000;
  top: 0;
}
.animated-title > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
}
.animated-title > div.text-top div span:first-child {
  color: white;
}
.animated-title > div.text-bottom {
  bottom: 0;
}
.text-second {
  color: white;
  font-weight: normal;
  vertical-align: baseline;
}
.animated-title > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}
.bubble-most {
  width: 80px;
  height: 80px;
  border-radius: 160px;
  position: absolute;
  max-width: max-content;
  margin-top: -9px;
}

.bubble-most-small {
  width: 35px;
  height: 35px;
  border-radius: 160px;
  position: absolute;
  max-width: max-content;
  margin-top: -41px;
  margin-left: 72px;
}
.bubble-most-medium {
  position: absolute;
  left: auto;
  right: 0;
  margin-top: -186px;
  width: 180px;
  border-radius: 160px;
  margin-right: 209px;
}
.carusel-docker {
  margin-top: -7px;
}
.carousel-oddo {
  justify-content: center;
  margin-top: 21px;
}
.carousel-tools-mobile-2 {
  margin-top: 24px;
}
.img-carusel-datadog {
  width: 99px !important;
}
.img-carusel-docker {
  width: 127px !important;
}
.bubble-codification {
  position: absolute;
  left: auto;
  margin-top: -129px;
  width: 216px;
  border-radius: 160px;
  margin-left: 266px;
  z-index: 2;
}
.bubble-codification-1 {
  position: absolute;
  left: auto;
  margin-top: 34px;
  width: 113px;
  border-radius: 160px;
  margin-left: 377px;
}
.section-text-area {
  padding-left: 5px;
  padding-right: 5px;
}
.subtitle-exploration {
  color: white;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.text-optimization {
  color: white;
  font-size: 25px;
  text-align: left;
  line-height: normal;
  font-weight: 370;
}
.text-optimization span {
  font-style: italic;
  font-weight: 600;
}
.p-optimization,
.text-exploration {
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
  line-height: 27px;
}
.text-garantia {
  font-size: 27px;
  font-weight: 400;
}
.bubble-codification-2 {
  position: absolute;
  right: auto;
  margin-top: -31px;
  left: 0;
  width: 80px;
  border-radius: 160px;
  margin-left: 266px;
  z-index: 2;
}
.bubble-codification-3 {
  position: absolute;
  right: auto;
  margin-top: 22px;
  left: 0;
  width: 105px;
  border-radius: 160px;
  margin-left: 302px;
}
.image-data-dog {
  width: 100px !important;
}
.image-data-docker {
  width: 181px !important;
}

.image-data-oddo {
  width: 200px !important;
}
.background-because {
  width: 95%;
  height: auto;
  object-fit: cover;
  object-position: 50% 50%;
  background-image: url('https://static.wixstatic.com/media/a7b7b2_0adbb329c41f446eb526c632aabc5696~mv2.jpg/v1/crop/x_0,y_102,w_3000,h_1797/fill/w_1948,h_1244,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/SCiconnectdots_edited.jpg');
  border-right: 4px solid rgb(1 255 208);
}
.background-section-contact {
  background-image: url('https://static.wixstatic.com/media/ad9488_4902ebc21b0644eaa6bb7531a989162c~mv2.jpg/v1/crop/x_0,y_192,w_2250,h_883/fill/w_1584,h_620,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/moshi%20creek_edited_edited_edited.jpg');
  width: 100%;
  height: 496px;
  text-align: -webkit-center;
  object-fit: cover;
  object-position: 50% 50%;
}
.row-client-2 {
  align-items: center;
  justify-content: center;
}
.text-vision {
  color: white;
  font-weight: 500;
  font-size: 31px;
}
.title-because {
  padding-top: 100px;
  text-align: left;
  color: white;
  font-weight: 500;
}
.text-because {
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 3px;
  line-height: 24px;
  margin-left: 27px;
}
.text-section-service {
  font-size: 50px;
  text-align: left;
  line-height: 60px;
  font-weight: 660;
}

.title-services {
  font-size: 14px;
  color: white;
  font-weight: 600;
}
.text-services {
  font-size: 14px;
  color: white;
  font-weight: 200;
  line-height: normal;
}
.ul-because {
  line-height: 30px;
}
.text-section-contact {
  font-size: 38px;
  text-align: left;
  line-height: 44px;
  font-weight: 400;
}
.section-contact {
  padding-top: 6px;
  padding-left: 119px;
}
.title-result {
  font-size: 20px;
  font-weight: 600;
}
.text-result {
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 32px;
}
.col-result {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.title-result-1 {
  padding-top: 10px;
  text-align: left;
  color: black;
  font-weight: 500;
}
.content-result-1 p {
  font-weight: 300;
  line-height: normal;
  font-size: 15px;
}
.background-mantenimiento {
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: #003b68;
  height: 100vh;
}
.img-mantenimiento {
  width: 350px;
}
.text-mantenimiento {
  font-family: monospace;
  letter-spacing: 4px;
  margin-top: -8px;
  font-size: 30px;
}
.carousel-tools-mobile {
  display: none;
}
.carousel-tools-web {
  display: block;
}
.contador-mantenimiento {
  font-family: monospace;
  letter-spacing: 4px;
  font-size: 30px;
  color: white;
}
::placeholder {
  color: white;
}

.title-contacto {
  margin-top: 3rem !important;
  margin-bottom: 20px;
  font-weight: 600 !important;
}
.input-contact {
  background: #b5b5b5;
  height: 48px;
  padding: 18px;
  color: black;
  border: 0px;
  outline: none;
  margin-bottom: 25px;
  outline: none !important;
}
.text-area-contact {
  max-width: 100%;
  min-width: 100%;
  height: 132px;
  border-radius: 19px;
  margin-bottom: 21px;
  background: #b5b5b5;
  padding: 18px;
  outline: none !important;
}
.button-contact {
  background: #378ac7;
  outline: none !important;
  padding: 10px;
  border-radius: 19px;
  padding-left: 36px;
  padding-right: 36px;
  color: white;
  border: 0;
  font-weight: 600;
  margin-bottom: 29px;
  font-size: 20px;
}

.form-contact {
  place-content: center;
  margin-top: 35px;
}

.title-product {
  font-size: 36px;
  margin-top: 162px;
  font-weight: 700;
  letter-spacing: -0.25px;
  line-height: 44px;
  margin-bottom: 35px;
}
.text-analitic {
  font-size: 19px;
  font-weight: bold;
}
.text-second-home-contact {
  font-size: 20px;
  font-weight: 500;
}
.content-title-aviso-privacidad{
  font-weight: bold; 
}
.content-section-link-privacidad{
  font-weight: bold;
  color: #3787c5;
}
.content-section-ul-aviso-privacidad,.content-section-ul-second-aviso-privacidad{
  margin: 10px 0px;

}
.content-section-ul-aviso-privacidad{
    list-style: disc;
    color: #3787c5;
}
.content-section-ul-aviso-privacidad li span{
  color: black;
}
.content-section-ul-second-aviso-privacidad{
  text-decoration: none;
  list-style: none;
}
.content-section-ul-second-aviso-privacidad .bold{
    color: #3787c5;
    margin-right: 1px;
}
.label-checkbox-termino-contact{
  color: white;
  margin-bottom: 20px;
  font-size: 12px;
}
.label-checkbox-termino-contact input{
  margin-right: 10px;
  vertical-align: middle;
  width: 14px;
  height: 14px;
}
.content-section-link-contacto{
   color: #3787c5;
}

@media (min-width: 1200px) {
  .background-section-contact {
    width: 1267px;
  }
}

@media (max-width: 1200px) {
  .img-result {
    width: 100%;
  }
  .bubble-most-medium,
  .bubble-codification,
  .bubble-codification-1,
  .bubble-codification-2,
  .bubble-codification-3 {
    display: none;
  }
  .section-contact {
    text-align-last: center !important;
    padding-left: 0px;
  }
}
@media (max-width: 800px) {
  .section-tools .carousel {
    height: 160px;
  }
  .carusel-row {
    align-items: center;
  }

  .carousel-tools-web {
    display: none;
  }
  .carousel-tools-mobile {
    display: block;
  }
  .title-first {
    font-size: 31px;
  }

  .text-first {
    color: white;
    vertical-align: baseline;
    font-size: 24px;
  }
  .animated-title > div div {
    font-size: 16px;
    padding: 2vmin 0;
    position: absolute;
    font-weight: 600;
  }

  .background-home {
    height: 500px;
    background: #3787c5;
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: -50px;
    justify-content: center;
  }
  .animated-title {
    color: #222;
    height: 100vmin;
    left: 50%;
    font-weight: 600;
    position: absolute;
    top: 29%;
    transform: translate(-50%, -50%);
    width: 90vmin;
    margin-top: 105px;
  }
  .title-contacto {
    margin-top: 1rem !important;
  }
  .contador-mantenimiento {
    font-family: monospace;
    letter-spacing: 1px;
    font-size: 25px;
    color: white;
  }
  .background-mantenimiento {
    background-position: center center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #003b68;
    top: 0;
    right: 0;
    width: 100%;
    height: 103vh;
    z-index: 10000;
    margin-top: 0;
    border-radius: 0;
    padding: 20px 30px;
  }
  .text-mantenimiento {
    font-family: monospace;
    letter-spacing: 3px;
    margin-top: -32px;
    font-size: 25px;
  }
  .img-mantenimiento {
    width: 250px;
    margin-bottom: 20px;
  }
  .col-service-1 {
    padding-left: 0px !important;
    padding: 2px;
  }
  .container-service {
    text-align: center;
  }
  .col-service {
    margin-bottom: 22px;
  }
  .text-align-center-m {
    text-align: center !important;
  }
  .text-section-service {
    font-size: 38px;
    text-align: left;
    line-height: 48px;
    font-weight: 660;
    padding-top: 38px !important;
    padding-bottom: 20px !important;
  }
  .col-result {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .title-result {
    margin-top: 10px;
  }
  .text-result {
    font-size: 18px;
  }
  .img-result {
    width: 100%;
  }
  .text-because {
    font-size: 15px;
    font-weight: 300;
    letter-spacing: 1px;
    line-height: 24px;
    margin-left: 12px;
  }
  .text-analitic {
    font-size: 15px;
    font-weight: normal;
    line-height: 25px;
  }
  .text-optimization-1 {
    font-size: 25px !important;
  }
  .subtitle-exploration {
    color: white;
    width: 100%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .bubble-most,
  .bubble-most-medium,
  .bubble-most-small {
    display: none;
  }
  .title-product {
    font-size: 30px;
    margin-top: 124px;
    font-weight: 700;
    letter-spacing: -0.25px;
    line-height: 44px;
    margin-bottom: 35px;
  }
}
@media (max-width: 620px) {
  .card-image-generic-service,
  .card-image-generic-service1 {
    width: 145px;
  }
  .second-about {
    font-size: 18px;
    line-height: 22px !important;
  }
  .section-text-area {
    width: 98%;
  }
  .img-contact {
    margin-top: 29px;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .input-contact {
    width: 98%;
  }
  .subtitle-exploration {
    color: white;
    width: 100%;
    text-align: center;
    font-weight: normal;
    line-height: 25px;
    font-size: 15px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 400px) {
  .p-optimization,
  .text-exploration {
    font-size: 15px;
    font-weight: normal;
    line-height: 25px;
  }
  .title-first {
    font-size: 23px;
  }
  .title-exploration {
    font-size: 27px !important;
  }
  .text-first {
    color: white;
    vertical-align: baseline;
    font-size: 20px;
  }
  .text-mantenimiento {
    font-family: monospace;
    letter-spacing: 3px;
    margin-top: -32px;
    font-size: 15px;
  }
  .img-mantenimiento {
    width: 250px;
    margin-bottom: 20px;
  }
  .contador-mantenimiento {
    font-family: monospace;
    letter-spacing: 1px;
    font-size: 20px;
    color: white;
  }
}
