.nav-bar-p {
  display: inline-block;
  margin: 0;
  line-height: 21px;
  font-size: 15px;
  color: white;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 1px;
  font-family: 'Montserrat', sans-serif;
}
.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.btn-principal {
  background: #3787c5;
  border-radius: 19px;
  color: black;
  text-align: center;
}

.offcanvas-title {
  font-weight: bold;
  text-transform: uppercase;
}
.dropdown-toggle {
  color: #275cbb !important;
}
@media (max-width: 1335px) {
  .nav-bar-p {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .btn-principal {
    margin-top: 12px;
  }
}
